<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      @hidden="hide"
    >
      <template v-if="$store.state.theme.line_layout == 'Compact'">
        <div class="text-center mb-4">
          <p class="text-text f-xl" v-if="text">{{ text }}</p>
        </div>
        <div class="text-center">
          <font-awesome-icon icon="check-circle" size="5x" class="mb-4" />
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <font-awesome-icon icon="check-circle" size="5x" class="mb-4" />
        </div>
        <div class="text-center mb-4">
          <p class="text-text f-xl" v-if="text">{{ text }}</p>
        </div>
      </template>

      <!-- <div class="border-top p-2 text-center btn-confirm-popup"> -->
      <b-button block variant="confirm-primary" @click="showModal = false">{{
        closeText || "ยืนยัน"
      }}</b-button>
      <!-- </div> -->
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    },
    closeText: {
      required: false
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.$emit("show");
    },
    hide() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  color: #000;
  margin-bottom: 0;

  font-weight: 600;
}
.modal-body {
  margin: auto;
  text-align: center;
}
.class-icon-black {
  width: 70px;
}
svg[data-icon="check-circle"] {
  color: var(--primary-color);
}
</style>
