<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div class="bg-white page-scroll" v-else>
      <PdpaFormPanel :list="pdpaList" :form="form" @getPdpa="getPdpa" />
    </div>
    <ModalAlert ref="modalAlert" :text="msg" @hide="hideSuccess" />
    <ModalRedeemError ref="ModalRedeemError" :text="msg" />
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import PdpaFormPanel from "@/components/pdpa/PdpaFormPanel";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalRedeemError from "@/components/profile/modal/ModalRedeemError";
export default {
  components: {
    OtherLoading,
    PdpaFormPanel,
    ModalAlert,
    ModalRedeemError
  },
  data() {
    return {
      isLoading: false,
      pdpaList: [
        { text: "ขอเข้าถึงข้อมูล", value: 1 },
        { text: "ขอให้โอนข้อมูล", value: 2 },
        { text: "ขอให้เพิกถอนความยินยอม", value: 3 },
        { text: "ขอแก้ไขข้อมูล", value: 4 },
        { text: "ขอให้ระงับและคัดค้านการใช้ข้อมูล", value: 5 },
        { text: "ขอให้ลบข้อมูล", value: 6 }
      ],
      form: {
        user_guid: "",
        request_type_id: null,
        note: null
      },
      profile: {},
      msg: ""
    };
  },
  async created() {
    await this.getDataProfile();
  },
  methods: {
    async getDataProfile() {
      await this.$store.dispatch("getUserApi");
      this.profile = this.$store.state.shortProfile;
      this.form.user_guid = this.profile.user_guid;
    },
    async getPdpa(form) {
      this.isLoading = true;
      await this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/DSR/send_request`, form)
        .then(async data => {
          if (data.result == 1) {
            if (
              form.request_type_id === 1 ||
              form.request_type_id === 2 ||
              form.request_type_id === 3 ||
              form.request_type_id === 5 ||
              form.request_type_id === 6
            ) {
              this.isLoading = false;
              this.msg = data.message;
              this.$refs.modalAlert.show();
            } else if (form.request_type_id === 4) {
              this.$router.push(
                `/manage/profile?pdpa=${this.form.request_type_id}`
              );
            }
          } else {
            this.isLoading = false;
            this.msg = data.message;
            this.$refs.ModalRedeemError.show();
          }
        });
    },
    hideSuccess() {
      this.$router.push("/profile");
    }
  }
};
</script>

<style lang="scss" scoped></style>
